<template>
  <v-container fluid>
    <v-radio-group v-model="locale" row>
      <v-radio v-for="(locale, index) in localesDictionary" :key="index" :label="locale.name" :value="locale.value"></v-radio>
    </v-radio-group>
    <v-row>
      <v-col cols="12">
        <div class="custom-field">
          <v-select
            label="Категорія"
            v-model="editedItem.category.id"
            :items="additionalInfoCategories"
            item-value="id"
            :disabled="!!this.categoryId"
            @input="$v.editedItem.category.id.$touch()"
            @blur="$v.editedItem.category.id.$touch()"
            :error-messages="categoryErrors"
          >
            <template v-slot:item="{ item }">{{ item.name[locale] }}</template>
            <template v-slot:selection="{ item }">{{ item.name[locale] }}</template>
          </v-select>
          <v-text-field
            v-model="editedItem.name[locale]"
            prepend-icon="mdi-text-box-edit-outline"
            label="Назва статті"
            clearable
            @input="$v.editedItem.name[$currentLocale].$touch()"
            @blur="$v.editedItem.name[$currentLocale].$touch()"
            :error-messages="nameErrors"
          ></v-text-field>
          <v-text-field
            v-model.number="editedItem.sort_number"
            label="Номер банера"
            clearable
            @input="$v.editedItem.sort_number.$touch()"
            @blur="$v.editedItem.sort_number.$touch()"
            :error-messages="sortNumberErrors"
          ></v-text-field>
        </div>
        <div>
          <v-btn @click="showEvents = true" :disabled="showEvents || isEditable"> прив'язати статтю до події </v-btn>
        </div>
        <div style="height: 80px" class="mt-5">
          <v-expand-transition>
            <v-card v-show="showEvents || isEditable" class="custom-field px-2" color="#eceff1">
              <div class="d-flex align-baseline">
                <div class="flex-grow-1">
                  <PagingAutocomplete
                    :value="selectedEvent"
                    :options="additionalInfoEvents"
                    :options-meta="additionalInfoEventsMeta"
                    :options-params="eventsOptions"
                    @click:clear="clearEvent"
                    @change="selectEvent"
                    item-value="id"
                    no-filter
                    hide-no-data
                    clearable
                    label="Подія"
                    placeholder="Почніть вводити текст для пошуку"
                    prepend-icon="mdi-text-box-edit-outline"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.name[locale] }} ({{ item.event_code }}), {{ item.event_type.name[locale] }} ({{
                        item.event_type.code
                      }})
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item.name[locale] }} ({{ item.event_code }}), {{ item.event_type.name[locale] }} ({{
                        item.event_type.code
                      }})
                    </template>
                  </PagingAutocomplete>
                </div>
              </div>
            </v-card>
          </v-expand-transition>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <vue-editor v-model="editedItem.articles[locale]" placeholder="Текст статті" />
      </v-col>
    </v-row>
    <template v-if="additionalInfoArticle.images && additionalInfoArticle.images.length">
      <div class="mt-5 mb-2">Зображення:</div>
      <v-row>
        <template v-for="(image, index) in additionalInfoArticle.images">
          <v-col v-show="index.toString() !== selectedImageFile" :key="index" cols="12" md="4">
            <v-hover v-slot="{ hover }" :disabled="!!selectedImageFile">
              <v-card :elevation="hover ? 8 : 2" class="pa-5">
                <v-img :src="`${baseURL}/${image}`" height="225px" contain>
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out v-card--reveal"
                      style="height: 100%; background-color: rgba(0, 0, 0, 60%)"
                    >
                      <v-btn :href="`${baseURL}/${image}`" target="_blank" class="mx-2" fab>
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                      <v-btn @click="deleteImageFile(image, index)" class="mx-2" fab>
                        <v-icon color="red">mdi-delete-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-expand-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </template>
    <template v-if="additionalInfoArticle.images && additionalInfoArticle.images.length">
      <div class="mt-5 mb-2">Зображення:</div>
      <v-row>
        <template v-for="(video, index) in additionalInfoArticle.videos">
          <v-col v-show="index.toString() !== selectedVideoFile" :key="index" cols="12" md="4">
            <v-hover v-slot="{ hover }" :disabled="!!selectedVideoFile">
              <v-card :elevation="hover ? 8 : 2" class="pa-5">
                <video controls width="100%">
                  <source :src="`${baseURL}/${video}`" type="video/mp4" />
                  <source :src="`${baseURL}/${video}`" type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out v-card--reveal"
                    style="
                      height: calc(100% - 45px);
                      width: calc(100% - 40px);
                      top: 19px;
                      border-radius: 4px;
                      background-color: rgba(0, 0, 0, 60%);
                    "
                  >
                    <v-btn @click="deleteVideoFile(video, index)" class="mx-2" fab>
                      <v-icon color="red">mdi-delete-outline</v-icon>
                    </v-btn>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </template>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-file-input
          @change="selectImage"
          :rules="rules"
          accept="image/*"
          truncate-length="30"
          prepend-icon="mdi-image"
          show-size
          label="Додати файл зображення / GIF-анімації"
        ></v-file-input>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-file-input
          @change="selectVideo"
          :rules="rules"
          accept="video/*"
          truncate-length="30"
          prepend-icon="mdi-video"
          show-size
          label="Додати файл відео"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-container fluid>
      <div class="d-flex justify-end">
        <v-btn @click="$router.go(-1)" class="mr-2" small>Назад</v-btn>
        <v-btn @click="openModal" color="primary" small>Зберегти</v-btn>
      </div>
    </v-container>

    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="saveData" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { ADDITIONAL_INFO_ARTICLE_SCHEMA } from '@/const/apiSchemas'
import { ADDITIONAL_INFO_EVENT_CODE_SCHEMA } from '@/const/apiSchemas'
import { PACKAGE_TYPES } from '@/const/constructor/packages-types.enum'
import { PROTOCOL_TYPES } from '@/const/constructor/protocol-types.enum'
import cloneDeep from '@/mixins/cloneDeep'
import { VueEditor } from 'vue2-editor'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, integer } from 'vuelidate/lib/validators'
import { CURRENT_LOCALE } from '@/const/globals'

export default {
  name: 'AdditionalInfoArticleConstructor',

  mixins: [validationMixin, cloneDeep],

  validations: {
    editedItem: {
      name: {
        [CURRENT_LOCALE]: { required },
      },
      category: {
        id: { required },
      },
      sort_number: { integer },
    },
  },

  components: {
    PagingAutocomplete: () => import('@/components/common/PagingAutocomplete'),
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    VueEditor,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    ADDITIONAL_INFO_ARTICLE_SCHEMA: { ...ADDITIONAL_INFO_ARTICLE_SCHEMA },
    ADDITIONAL_INFO_EVENT_CODE_SCHEMA: ADDITIONAL_INFO_EVENT_CODE_SCHEMA,
    PACKAGE_TYPES: PACKAGE_TYPES,
    PROTOCOL_TYPES: PROTOCOL_TYPES,
    rules: [(value) => !value || value.size < 10000000 || 'Розмір файлу не повинен перевищувати 10 Мб!'],
    loading: false,
    editedItem: {},
    defaultItem: {},
    sort_number: null,
    selectedEvent: null,
    searchEvent: null,
    isEventLoading: false,
    showModal: false,
    showEvents: false,
    imageFile: null,
    videoFile: null,
    selectedImageFile: null,
    selectedVideoFile: null,
    imageFileLink: '',
    videoFileLink: '',
    locale: CURRENT_LOCALE,
    eventsOptions: null,
  }),

  computed: {
    ...mapState('infoConstructor', [
      'additionalInfoEvents',
      'additionalInfoEventsMeta',
      'additionalInfoArticle',
      'additionalInfoCategories',
    ]),
    ...mapState('dictionaries', ['localesDictionary']),
    ...mapGetters('profile', ['getCurrentUserLocale']),

    categoryId() {
      return this.$route.query.categoryId || null
    },

    categoryErrors() {
      const errors = []
      if (!this.$v.editedItem.category.id.$dirty) return errors
      !this.$v.editedItem.category.id.required && errors.push('Це поле обов"язкове')
      return errors
    },

    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name.uk.$dirty) return errors
      !this.$v.editedItem.name.uk.required && errors.push('Це поле обов"язкове (locale = uk)')
      return errors
    },

    sortNumberErrors() {
      const errors = []
      if (!this.$v.editedItem.sort_number.$dirty) return errors
      if (!this.$v.editedItem.sort_number.integer) {
        errors.push('Це поле приймає тільки число')
      }
      if (this.editedItem.sort_number === 0) {
        errors.push('Значення не може бути рівним 0')
      }
      return errors
    },
  },

  watch: {
    async searchEvent(searchEvent) {
      if (searchEvent) {
        this.isEventLoading = true
        await this.loadAdditionalInfoEventCodes(searchEvent)
        this.isEventLoading = false
      }
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.SET_ADDITIONAL_INFO_EVENT({})
  },

  methods: {
    ...mapActions('infoConstructor', [
      'createNewAdditionalInfoEvent',
      'updateSelectedAdditionalInfoEvent',
      'loadAdditionalInfoEventCodes',
      'loadAdditionalInfoCategories',
      'deleteSelectedAdditionalInfoEvent',
    ]),
    ...mapMutations('infoConstructor', ['SET_ADDITIONAL_INFO_EVENT', 'SET_SELECTED_ADDITIONAL_INFO_ARTICLE']),
    async initialize() {
      this.defaultItem = {
        ...this.cloneObjectDeepWithLocales(ADDITIONAL_INFO_ARTICLE_SCHEMA, this.localesDictionary),
        category: { ...this.editedItem.category, id: this.$route.query.categoryId || null },
      }
      this.eventsOptions = {
        loadingFunction: this.loadAdditionalInfoEventCodes,
        payload: {
          forSearch: 1,
        },
      }
      if (this.isEditable) {
        this.editedItem = this.cloneObjectDeep(this.additionalInfoArticle)
        this.selectedEvent = this.additionalInfoArticle.event_code
      } else {
        // this.ADDITIONAL_INFO_ARTICLE_SCHEMA = { ...this.cloneObjectDeep(this.defaultItem) }
        // cloneObjectDeep для редактирования отдельной локали
        // this.editedItem = this.cloneObjectDeep(this.ADDITIONAL_INFO_ARTICLE_SCHEMA)
        // first level values doesn't editable because Object is freezed
        this.editedItem = {
          ...this.cloneObjectDeepWithLocales(ADDITIONAL_INFO_ARTICLE_SCHEMA, this.localesDictionary),
          category: { ...this.editedItem.category, id: this.$route.query.categoryId || null },
        }
        this.selectedEvent = this.cloneObjectDeepWithLocales(this.ADDITIONAL_INFO_EVENT_CODE_SCHEMA, this.localesDictionary)
      }
      if (!this.additionalInfoCategories.length) {
        await this.loadAdditionalInfoCategories({ forSearch: 1 })
      }
    },
    selectEvent(item) {
      if (!item) {
        this.editedItem.event_code = null
        return
      }
      if (!this.editedItem.event_code) this.editedItem.event_code = {}
      this.editedItem.event_code.id = item.id
      this.editedItem.event_code.protocol = item.protocol
      this.editedItem.event_code.event_type = { ...item.event_type }
    },
    clearEvent() {
      this.SET_ADDITIONAL_INFO_EVENT_CODES([])
      this.editedItem.event_code.id = null
      this.editedItem.event_code.protocol = null
      this.editedItem.event_code.event_type = null
    },
    selectImage(file) {
      this.imageFile = file
    },
    selectVideo(file) {
      this.videoFile = file
    },
    openModal() {
      this.$v.editedItem.name.uk.$touch()
      this.$v.editedItem.category.id.$touch()
      if (this.$v.$anyError) return
      this.showModal = true
    },
    saveData() {
      if (this.selectedImageFile) this.$emit('deleteFile', this.imageFileLink)
      if (this.selectedVideoFile) this.$emit('deleteFile', this.videoFileLink)
      const requiredKeys = ['id', 'protocol']
      if (this.editedItem.event_code) {
        this.editedItem.event_code = Object.fromEntries(
          Object.entries(this.editedItem.event_code).filter(([key]) => requiredKeys.includes(key))
        )
      }
      this.$emit('saveData', this.editedItem, this.imageFile, this.videoFile)
    },
    deleteImageFile(link, index) {
      this.imageFileLink = link
      this.selectedImageFile = index.toString()
    },
    deleteVideoFile(link, index) {
      this.videoFileLink = link
      this.selectedVideoFile = index.toString()
    },
  },
}
</script>

<style scoped lang="scss">
.custom-field {
  max-width: 600px !important;
  margin-bottom: 10px;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
